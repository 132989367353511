import {  useDispatch, useSelector } from "react-redux";
import { usersSelector } from "../../../redux/modules/users/selectors";
import { EmptyList } from "../../../components/EmptyList";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { httpGetActivities, httpPostActivitiesFilter } from "./api";
import Paginate from "../../../components/Paginate";
import Spinner from "../../../components/Spinner";
import { NavbarToggler } from "../../../components/NavbarToggler";
import styles from "./style.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { RootState } from "../../../redux/utils/types";
import { getAllFavoriteFolders } from "../../../redux/modules/favorites/action";
import { favoriteFoldersSelector } from "../../../redux/modules/favorites/selectors";
import { companySelector } from "../../../redux/modules/company/selectors";
import { getCompanies } from "../../../redux/modules/company/actions";

export default function ActivityFeed() {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getAllFavoriteFolders());
    dispatch(getCompanies());
      
  },[dispatch]);

  const users = useSelector(usersSelector);
  const {map: tagsMap} = useSelector((state: RootState) => state.tags);
  const favoriteFoldersMap = useSelector(favoriteFoldersSelector)
  const companiesMap = useSelector(companySelector);

  
  const tagSelectOptions = useMemo(() => Array.from(tagsMap.values()).map(tag => ({
    value: tag.id,
    label: tag.tagName,
    })),[tagsMap]
  );

  const favoriteFoldersSelectOptions = useMemo(()=>Array.from(favoriteFoldersMap.values()).map(fav =>({
    value: fav.id,
    label: fav.folderName,
  })),[favoriteFoldersMap]) 


  const companyArray= useMemo(()=>Array.from(companiesMap.values()).map(comp =>({
    value: comp.id,
    label: comp.companyName
    })),[companiesMap]
  )

  //pagination states
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [activities, setActivities] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterInput , setFilterInput] = useState<any>({
    tags:[],
    companies:[],
    favoriteFolders:[]
  });
  
  useEffect(() => {
    const filters = getFilters();
   
    const isDateRangeIncomplete = (startDate && !endDate) || (!startDate && endDate);
  
    if (isDateRangeIncomplete ) {
    return; 
    }
    const hasFilters =  (filters.dateRange !== null) || 
    filters.companyFilter.length > 0 ||
    filters.tagsFilter.length > 0 ||
    filters.favoriteFolderFilter.length > 0;
    setLoading(true);
  
    const fetchActivities = hasFilters
      ? httpPostActivitiesFilter(page, filters.dateRange, filters.companyFilter,filters.tagsFilter,filters.favoriteFolderFilter)
      : httpGetActivities(page);
  
    fetchActivities
      .then(({ activities = [], pageCount, total }) => {
        setActivities(activities);
        setPageCount(pageCount);
        setTotal(total);
      })
      .finally(() => setLoading(false));
  }, [page, startDate, endDate, filterInput]);

  
  
  const getFilters = () =>{
    const {tags,companies,favoriteFolders} = filterInput;
    return {
      dateRange: (startDate && endDate) ?{startDate, endDate} : {},
      companyFilter: companies ?companies:[],
      tagsFilter:tags ? tags :[],
      favoriteFolderFilter: favoriteFolders?favoriteFolders : []
    }
  }

  const handlePageChange = (event: any) => {
    setPage(event.selected + 1);
  };

  const handleDateRangeChange = (update: [Date | null, Date | null]) => {
    setDateRange(update);
  };

  
  return (
    <>
      {loading && <Spinner />}
      <div className="page-content p-0 bg-white" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Activity</h2>
              <NavbarToggler />
            </div>
          </div>

        <div className="row d-flex justify-content-between pl-5" >
        {/* <div className="row d-flex justify-content-end pl-5" > */}
        <div className={styles.select_container}>
              <Select
                placeholder="Select Company Tags"
                isMulti={true}
                closeMenuOnSelect={true}
                options={tagSelectOptions}
                onChange={(e)=>{
                  setFilterInput({
                    ...filterInput,
                    tags:e.map((t:any)=>t.value)
                  })
                }}
              
              />
          </div>
        {/* <div className={styles.select_container}>
              <Select 
                placeholder="Select Companies"
                isMulti
                closeMenuOnSelect={true}
                options={companyArray}
                onChange={(e)=>{
                  setFilterInput({
                    ...filterInput,
                    companies:e.map((c:any)=>c.value)
                  })
                }}
              
              />
          </div> */}
          {/* <div className={styles.select_container}>
              <Select 
                placeholder="Select Favorite Folder"
                closeMenuOnSelect={true}
                isMulti
                options={favoriteFoldersSelectOptions}
                onChange={(e)=>{
                  setFilterInput({
                    ...filterInput,
                    favoriteFolders:e.map((f:any)=>f.value)
                  })
                }}
              
              />
          </div> */}

          <div style={{marginRight:80}}>
            
          <i className="fa fa-solid fa-calendar fa-lg mr-2"></i>

          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateRangeChange} 
            placeholderText="Select Date Range"
            className={styles.customDatepicker}
          />  
          
          </div>
        </div>
        </div>
        <div className="container-fluid px-0">
          <div className="table-responsive" id="collapse1">
            <table className="table global-table">
              <thead>
                <tr>
                  <th scope="row">No.</th>
                  <th scope="row">Activity</th>
                  <th scope="row">Date</th>
                  <th scope="row">Time</th>
                  <th scope="row">User ID</th>
                </tr>
              </thead>

              <tbody className="text-center">
                <>
                  {activities.map((act: any, i: number) => {
                    const {
                      createdBy,
                      updatedBy,
                      createdAt,
                      updatedAt,
                      type,
                      activity,
                      company,
                      companyId
                    } = act;
                    const userId = type === "create" ? createdBy : updatedBy;
                    const username = users.get(userId.toString())?.user_id;

                    const date = new Date(updatedAt ?? createdAt);

                    let mes ;
                    if (activity === "company") {

                      mes =(
                        <>
                          {type === "create" ? "New Company added named " : ""}
                          <Link to={`/home/view/${companyId}`}>{company}</Link>
                          {type !== "create" ? " info updated" : ""}
                        </>
                      )
                      

                    } else {
                      mes = (
                        <>
                          {activity[0].toUpperCase() + activity.substring(1)}{" "}
                          {type === "update" ? "updated in" : "added in"}{" "}
                          <Link to={`/home/view/${companyId}`}>{company}</Link>
                        </>
                      );
                    }
                    const offset = (page - 1) * 15;
                    return (
                      <tr key={i + offset} >
                        <th scope="row">{i + offset + 1}</th>
                        <td style={{ maxWidth: "250px" }}>{mes}</td>
                        <td>
                          {date.toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </td>
                        <td>
                          {date.toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </td>
                        <td>{username}</td>
                      </tr>
                    );
                  })}
                  <tr className={styles.__page_fix__}>
                    <td colSpan={5}>
                      <div>
                        <Paginate
                          page={page}
                          pageCount={pageCount}
                          handlePageChange={handlePageChange}
                          total={total}
                        />
                      </div>
                    </td>
                  </tr>
                </>
                {activities.length === 0 && (
                  <EmptyList title="No Activity Found :(" />
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="footer">
          <Link to="/terms">Terms &amp; Condition Privacy Policy</Link>
        </div>
      </div>
    </>
  );
}

