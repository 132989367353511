// selectors.ts
import { RootState } from "../../utils/types";

// export const favoriteFoldersSelector = (state: RootState) => ({
//     status: state.favoriteFolders.status,
//     message: state.favoriteFolders.message,
    
//     //companies: state.favoriteFolders.companies,
// });

export const favoriteFoldersSelector = (state: RootState) =>
  state.favoriteFolders.map