import axios from "../../../adapters/axios";

export const httpGetActivities = async (page: number) => {
  const response = await axios.get(`activity/${page}`);
  return response.data;
};

export const httpPostActivitiesFilter = async(page:number, dateRange: { startDate?: Date; endDate?: Date },company:number[],tags:number[],favoriteFolder:number[])=>{
  const response = await axios.post(`activity/filter/${page}`, { date: dateRange , company:company,tag:tags,favoriteFolder:favoriteFolder});
  return response.data;
}
