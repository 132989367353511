import { useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import style from "./style.module.css";
import { authSelector } from "../../../redux/modules/auth/userSelector";
import { tagsSelector } from "../../../redux/modules/tags/selector";
import { companySelector } from "../../../redux/modules/company/selectors";
import { RootState } from "../../../redux/utils/types";
import Spinner from "../../../components/Spinner";
import CompanyList from "../../../components/CompanyList";
import { NavbarToggler } from "../../../components/NavbarToggler";
import  axios from '../../../adapters/axios'


// Function to parse and render the text dynamically
const renderDynamicText = (text: string): JSX.Element[] => {
  let lines = text.split("\n").filter((line) => line.trim() !== ""); // Split content into lines

  return lines.map((line, index) => {
    // Check for numbered lists
    if (/^\d+\.\s/.test(line)) {
      return (
        <li key={index} style={{ marginBottom: "10px" }}>
          {line.replace(/^\d+\.\s/, "")}
        </li>
      );
    }

    // Check for bold text using **bold**
    if (/\*\*(.*?)\*\*/.test(line)) {
      const parts = line.split(/\*\*(.*?)\*\*/); // Split by bold markers
      return (
        <p key={index}>
          {parts.map((part, i) =>
            i % 2 === 1 ? <strong key={i}>{part}</strong> : part
          )}
        </p>
      );
    }

    // Default case: plain text as a paragraph
    return <p key={index}>{line}</p>;
  });
};

export default function Home() {
  const location = useLocation();
  const { responseData } = location.state;
  // console.log("Adding..... : ", location.state)
  // const { searchInput } = location.state;
  const [searchInput, setSearchInput] = useState(location.state.searchInput);
  // console.log("SearchInput ##### : ", searchInput);
  // const content = responseData?.response?.message?.content;
  const [contentValue, setContentValue] = useState(responseData?.response?.message?.content);

  const { user } = useSelector(authSelector);
  const tagsMap = useSelector(tagsSelector);
  const companiesMap = useSelector(companySelector);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { status } = useSelector((state: RootState) => state.company);
  let companiesArray = Array.from(companiesMap.values());

  const tags = Array.from(tagsMap.values());

  let companyTags: any = tags.filter((tag) => tag.tagType === "company");
  let productTags: any = tags.filter((tag) => tag.tagType === "product");
  let solutionTags: any = tags.filter((tag) => tag.tagType === "solution");

  const [searchInputValue, setSearchInputValue] = useState("");
  const [aisearchInput, setAISearchInput] = useState("");
  const [tagsInput, setTagsInput] = useState<number[]>([]);

  const animatedComponents = makeAnimated();

  companyTags = companyTags.map((tag: any) => {
    return { value: tag.id, label: tag.tagName };
  });

  productTags = productTags.map((tag: any) => {
    return { value: tag.id, label: tag.tagName };
  });

  solutionTags = solutionTags.map((tag: any) => {
    return { value: tag.id, label: tag.tagName };
  });

  const [filters, setFilters] = useState<any>({
    company: [],
    product: [],
    solution: [],
  });

  useEffect(() => {
    setTagsInput([...filters.company, ...filters.product, ...filters.solution]);
  }, [filters]);

  //AI Handling
  const handleAIGenerator = async () => {
    try {
      setIsLoading(true);
      const params = { question: aisearchInput };
      const response = await axios.get('aiGenerator', { params }); 
      if (response.status === 200) {
        setIsLoading(false);
        setContentValue(response?.data?.response?.message?.content);
        setSearchInput(aisearchInput)
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Go to dashboard
  const goToDashboard = async () => {
    navigate("/home");
  };

  // filter based on tags
  /*
    1. create array of all filter tag ids
    2. create set of each company tags ids
    3. if set contains any tagId from filter tag array, include else exclude
  */

  const filterSet = new Set<number>();
  filters.company.forEach((tag: number) => filterSet.add(tag));
  filters.product.forEach((tag: number) => filterSet.add(tag));
  filters.solution.forEach((tag: number) => filterSet.add(tag));
  companiesArray = companiesArray.filter((company) => {
    if (
      searchInput &&
      company.companyName.toLowerCase().includes(searchInput.toLowerCase())
    ) {
      return true;
    }
    if (company.companyTags) {
      for (const tag of company.companyTags) {
        if (filterSet.has(+tag.id)) {
          return true;
        }
      }
    }
    return false;
  });

  // }
  companiesArray = companiesArray.filter(
    (company) => company.flagColor !== "black" && company.flagColor !== "orange"
  );
  // ui fix
  const styles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const keywordInParam = searchParams.get("keyword");
  return (
    <>
      <div>
        {/* <div className="" id="sidebar">
          <h1 className="logo">Wendwise</h1>
        </div> */}
        <div className="page-content m-0 p-0 vw-100 bg-white" id="content">
          <div className="row-header p-3">
            <div className="container-fluid">
              <div className="align-items-center d-flex justify-content-between">
                <h1 className="logo pl-0 pt-0">
                  Wendwise
                  <sup className="tm">TM</sup>
                </h1>
                <h2 className="head text-center mr-5">AI Assistant</h2>
                <button
                  className="col-md-1 btn btn-info btn-sm rounded-pill"
                  style={{ fontSize: "14px" }}
                  id="user-sbtn"
                  onClick={goToDashboard}  
                >
                  Dashboard
                </button>
                {/* <NavbarToggler /> */}   
              </div>
            </div>
          </div>
          {!user && <Navigate to="/login" />}
          {/* {status === "loading" && <Spinner />} */}
          {/* <div className="page-content bg-white ml-4 vh-100 pl-5" id="content"> */}
          <div className="page-content bg-white ml-4 pl-5" id="content">
            <div className="text-right">
              <div className="toggler p-2">
                <button
                  className="btn nav-btn navbar-toggler d-block d-lg-none pull-right"
                  type="button"
                >
                  <span className="fa fa-bars"></span>
                </button>
              </div>
            </div>
            <div
              className="row search-bar d-flex"
              style={{ paddingTop: "1rem" }}
            >
              <div className="col-md-11">
                <div className="input-box">
                  <div className="form-group has-search d-flex align-items-center">
                    <span className="fa fa-search form-control-feedback d-flex align-items-center"></span>
                    <input
                      type="text"
                      className="form-control flex-grow-1"
                      placeholder="Ask AI a Question to complement your Wendwise research"
                      value={aisearchInput}
                      onChange={(e) => setAISearchInput(e.target.value)}
                    />
                    <button
                      // href="search-result.html"
                      className="col-md-2 btn btn-info btn-sm ml-2 rounded-pill"
                      style={{ fontSize: "14px" }}
                      id="user-sbtn"
                      onClick={handleAIGenerator}
                      disabled={!aisearchInput.trim()}
                    >
                      Ask AI* 
                    </button>
                  </div>
                </div>
                <div className="">
                  <p className="text-dark" style={{ fontStyle: "italic", fontWeight: "600", fontSize: "13px" }}>*Please enter any question or topic that can enhance your research. The output is limited by design. Please do not enter credentials or private information - (Refer to our terms of service)</p>
                </div>
              </div>
            </div>
            <div className="">
              {/* <h3 className="h3">AI Generated Summary</h3> */}
              <div className="my-2 py-2">
                <h1 className="h2">{searchInput}</h1>
              </div>
              <div className="mr-2">
                <div className="">
                  <h5>Summary : </h5>
                </div>
                <div className="py-2 pr-2">
                 {/* // {contentValue} */}
                 {typeof contentValue === 'string' ? renderDynamicText(contentValue) : <p>Invalid content format</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer p-3 vw-100">
          <Link to="/terms">Terms &amp; Condition Privacy Policy</Link>
        </div>
        {isLoading && <Spinner />}
      </div>
    </>
  );
}