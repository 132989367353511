import { useSelector } from "react-redux";
import { companySelector } from "../../../redux/modules/company/selectors";
import CompanyList from "../../../components/CompanyList";
import { useState } from "react";
import Spinner from "../../../components/Spinner";
import { RootState } from "../../../redux/utils/types";
import { Link } from "react-router-dom";
import { NavbarToggler } from "../../../components/NavbarToggler";

export default function Flagged() {
  const companiesMap = useSelector(companySelector);
  console.log('companiesMap',companiesMap)
  let companyArray = Array.from(companiesMap.values());

  //filter only flagged companies
  companyArray = companyArray.filter((company) => company.flagColor !== "none");

  const [keyword, setKeyword] = useState("");
  const [tags, setTags] = useState<number[]>([]);
  const [flagged, setFlagged] = useState(true);

  const { status } = useSelector((state: RootState) => state.company);

  return (
    <>
      {status === "loading" && <Spinner />}
      <div className="page-content p-0" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Flagged</h2>
              <NavbarToggler />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="flag-content">
            <p>
              This section consist of the list of all the companies that are:
            </p>
            <ol>
              <li>
                In Search results but have gaps in information(
                <i className="fa fa-flag text-success"></i>)
              </li>
              <li>
                Not in Search Results and have gaps in information(
                <i className="fa fa-flag text-warning"></i>)
              </li>
              <li>
                Not in Search Results and Company Profile is incomplete(
                <i className="fa fa-flag text-dark"></i>)
              </li>
            </ol>
            <p>
              Users are invited to contact us in case they have information
              regarding the information gaps in the Company Details and help us
              build a more reliable information portal
            </p>
          </div>
        </div>

        <CompanyList filters={{ keyword, tags, flagged }} />

        {/* search results */}

        <div className="footer">
          <Link to="/terms">Terms &amp; Condition Privacy Policy</Link>
        </div>
      </div>
    </>
  );
}
